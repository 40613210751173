/* For Header.js */
.header {
    width: 100% !important;
    position: sticky !important;
    display: block !important;
    z-index: 500 !important;
    font-family: sans-serif !important;
    top: 0 !important;
    margin: 0 auto !important;
}
.row-header{
    height: 56px !important;
    background-color: #4A525A;
    margin: 0 !important;
    padding: 0 !important;
}

.logo {
    margin-top: -3px !important;
    width: 62px;
    height: 62px;
    margin-left: 28px !important;
}

.back-link {
    color: blue;
    text-decoration: none !important;
    font-size: 18px !important;
    margin-right: 5px !important;
    float: right !important;
    margin-top: -48px !important;
    background-color: transparent !important;
    outline: transparent !important;
    border: transparent !important;
}
.back-link:hover{
    color: white !important;
}
.back-link:focus, .back-link:active{
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.page-header {
    font-family: sans-serif;
    font-weight: bold;
    padding-top: 30px;
    font-size: 34pt !important;
    color: #4A525A !important;
    text-align: center !important;
    background-color: white;
    margin: 0 !important;
    padding: 0 !important;
}

/* For App.js */
.form-area {
    width: 100% !important;
    font-family: sans-serif !important;
    margin: 0 !important;
}

.form-column-row {
    margin: 0 auto !important;
    /* justify-content: center !important; */
}

.form-supplies-row,
.form-shipping-row {
    margin: 0 !important;
    /* justify-content: center !important; */
    /* width: 98% !important; */
}
.form-shipping-row{
    margin-top: 15px !important;
}

.buttons-row {
    justify-content: end !important;
    margin-top: 25px !important;
    margin-bottom: 5px !important;
    margin-right: 5px !important;
}

.submit-btn,
.cancel-btn {
    float: right !important;
    border-radius: 6px !important;
}

.cancel-btn {
    margin-right: 8px !important;
}

.submit-btn {
    background-color: #007AFF !important;
}

/* For SupplyCategories.js */
.card-col{
    margin-top: 10px !important;
}

.supply-block-subheads {
    font-size: 18px !important;
    color: #4A525A !important;
}

.supplies-card {
    width: 98% !important;
    justify-content: center !important;
    margin: auto !important;
    border-radius: 6px !important;
}

.card-supplies-row {
    margin: 5px !important;
}

.quantity-input {
    border-radius: 6px !important;
    margin-left: -12px !important;
    width: 120% !important;
}

.supplies-descriptions {
    white-space: none !important;
    line-height: 14px !important;
    text-align: left !important;
    margin-top: 8px !important;
}

.order-items {
    font-size: 16px !important;
    margin-left: -10px !important;
}

.wtr-sup-mssg-row{
    margin-top: 0px !important;
    padding-top: 0px !important;
    background-color: #e4e4e4;
}
.wtr-sup-mssg{
    font-family: sans-serif !important;
    font-size: 16px !important;
    text-align: center !important;
}

/* For ShinppingInfo.js */
.shipping-input {
    font-size: 14px !important;
}

.shipping-row {
    margin-bottom: 8px !important;
}

.info-labels {
    font-size: 13px !important;
    text-indent: -10px !important;
}

.shipping-col{
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.radio-btn-row{
    height: 25px !important;
}

.sup-mssg-row{
    margin: 0px !important;
    padding: 0px !important;
    background-color: #e4e4e4;
    margin-top: 20px !important;
}